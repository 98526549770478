
	import { Component, Emit, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { required } from 'vuelidate/lib/validators';

	import { Client, ClientVM, TeleinformationDetail } from '../../types/entities';
	import { validationMixin } from 'vuelidate';
	import {
		Actions as ClientActions,
		Getters as ClientGetters
	} from '@/modules/entities/store/modules/clients/types/StoreTypes';
	import { namespace } from 'vuex-class';
	import { AxiosError, AxiosResponse } from 'axios';
	import { JsonResource } from '@/core/types/Entities';

	const clients = namespace('entities/clients');

	@Component({
		mixins: [validationMixin],
		validations: {
			client: {
				name: { required }
			}
		}
	})
	export default class ClientModalComponent extends Vue {
		@PropSync('showModal', { type: Boolean, required: true }) showModalSync!: boolean;

		@clients.Action(ClientActions.CREATE_NEW_CLIENT) createNewClient!: (payload: Client) => Promise<AxiosResponse<JsonResource<Client>>>;

		@clients.Getter(ClientGetters.GET_LOADING) clientsLoading!: boolean;

		client: Client = new ClientVM({
			bankAccounts: [
				{
					id: 0,
					accountNumber: '',
					isMain: true
				},
				{
					id: 0,
					accountNumber: '',
					isMain: false
				}
			],
			teleinformations: [
				{
					id: 0,
					landlinePhone: '',
					landlinePhoneComment: '',
					mobilePhone: '',
					mobilePhoneComment: '',
					email: '',
					emailComment: '',
					isMainContactInformation: true,
					user: undefined,
					updatedAt: ''
				},
				{
					id: 0,
					landlinePhone: '',
					landlinePhoneComment: '',
					mobilePhone: '',
					mobilePhoneComment: '',
					email: '',
					emailComment: '',
					isMainContactInformation: false,
					user: undefined,
					updatedAt: ''
				}]
		});
		validationErrors: any = null;


		get primaryClientTeleinformations(): TeleinformationDetail {
			return this.client.teleinformations.find(teleinformationDetail => teleinformationDetail.isMainContactInformation)!;
		}

		get secondaryClientTeleinformations(): TeleinformationDetail {
			return this.client.teleinformations.find(teleinformationDetail => !teleinformationDetail.isMainContactInformation)!;
		}

		get nameErrors(): string[] {
			const errors: string[] = [];
			this.$v.client.name && !this.$v.client.name.required && errors.push('Naam is vereist');
			return errors;
		}

		onClientFormSubmit(): void {
			const requestBody = Object.assign({}, this.client);
			requestBody.isCompany = !!requestBody.vatNumber;
			requestBody.teleinformations = requestBody.teleinformations.filter(x => x.landlinePhone || x.landlinePhoneComment || x.mobilePhone || x.mobilePhoneComment || x.email || x.emailComment);
			this.createNewClient(requestBody)
				.then((res: AxiosResponse<JsonResource<Client>>) => {
					this.onEmitCreateClient(res.data.data);
				})
				.catch((err: AxiosError) => {
					if (err.response && err.response.status === 422) {
						this.validationErrors = err.response.data.errors;
					} else {
						throw err;
					}
				});
		}

		@Emit('onCreateClient')
		onEmitCreateClient(client: Client) {
			return client;
		}

		@Watch('client.name')
		onClientNameChange(): void {
			if (this.validationErrors && this.validationErrors.name) this.validationErrors.name = null;
		}
	}
